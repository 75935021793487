import axios from "@/api/axios"; // 引用axios

export const getHomeMapCount = (params) => {
    return axios({
        url: "/api/big/screen/getHomeMapCount",
        method: "post",
        params: params,
    });
};

export const getHomeMapPoint = (params) => {
    return axios({
        url: "/api/big/screen/getHomeMapPoint",
        method: "post",
        params: params,
    });
};

export const getCameraList = (params) => {
    return axios({
        url: "/api/big/screen/getCameraList",
        method: "post",
        params: params,
    });
};